/* 布局 */
import Layout from "@views/layout";

const systemRouter = {
  path: "/system",
  name: "SYSTEM",
  component: Layout,
  redirect: { name: "USER" },
  meta: {
    title: "系统设置",
    sort: 0,
  },
  children: [
    {
      path: "user",
      name: "USER",
      meta: {
        title: "用户管理",
        sort: 1,
      },
      component: () => import("@views/system/user.vue"),
      redirect: {
        name: "USER_LIST",
      },
      children: [
        {
          path: "list",
          name: "USER_LIST",
          meta: {
            title: "用户管理",
            sort: 2,
          },
          component: () => import("@views/system/user/list.vue"),
        },
        {
          path: "add",
          name: "USER_ADD",
          meta: {
            title: "新增用户",
            parentPath: '/system/user/list',
            operate: true,
          },
          component: () => import("@views/system/user/form.vue"),
        },
        {
          path: "edit/:id",
          name: "USER_EDIT",
          meta: {
            title: "编辑用户",
            parentPath: '/system/user/list',
            operate: true,
          },
          component: () => import("@views/system/user/form.vue"),
        },
      ],
    },
    {
      path: "role22",
      name: "ROLE22",
      meta: {
        title: "角色管理",
        sort: 1,
      },
      component: () => import("@views/system/role.vue"),
    },
    // {
    //   path: "branch",
    //   name: "BRANCH",
    //   meta: {
    //     title: "部门管理",
    //     sort: 1,
    //   },
    //   component: () => import("@views/system/branch.vue"),
    // },
    {
      path: "station",
      name: "STATION",
      meta: {
        title: "岗位管理",
        sort: 1,
      },
      component: () => import("@views/system/station.vue"),
    },
    {
      path: "authority",
      name: "AUTHORITY",
      meta: {
        title: "权限管理",
        sort: 1,
      },
      component: () => import("@views/system/authority.vue"),
    },
  ],
};

export default systemRouter;
