/**
 * 部门
 */
import Layout from "@views/layout";

const branchRouter = {
    path: "/branch",
    name: "BRANCH",
    component: Layout,
    redirect: { name: "BRANCH_INDEX" },
    meta: {
        title: "部门管理",
        sort: 0,
    },
    children: [{
        path: 'index',
        name: 'BRANCH_INDEX',
        meta: {
            title: '部门管理',
            sort: 2
        },
        component: () => import("@views/branch/index.vue"),
        redirect: {
            name: "BRANCH_LIST",
        },
        children: [
            {
                path: 'list',
                name: 'BRANCH_LIST',
                meta: {
                    title: '部门管理',
                    sort: 2
                },
                component: () => import("@views/branch/child/list.vue")
            },
            {
                path: 'add',
                name: 'BRANCH_ADD',
                meta: {
                    title: '新增',
                    operate: true,
                    parentPath: "/branch/index/list",
                },
                component: () => import("@views/branch/child/form.vue")
            },
            {
                path: "edit/:id",
                name: 'BRANCH_EDIT',
                meta: {
                    title: '修改',
                    operate: true,
                    parentPath: "/branch/index/list"
                },
                component: () => import("@views/branch/child/form.vue")
            }
        ]
    }]
}


export default branchRouter;