/* 布局 */
import Layout from "@views/layout";

const configureRouter = {
    path: "/configure",
    component: Layout,
    redirect: {
        name: "CONFIGURE"
    },
    children: [{
        path: "configureList",
        name: "CONFIGURE",
        alwaysShow: true,
        meta: {
            title: "配置管理"
        },
        component: () => import("@views/configure/index"),
    }],
};

export default configureRouter;