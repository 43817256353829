import API from "@utils/request";

const COMPANY = '/index/';

/**
 * 公司树形列表
 * @param {object} params 
 * @returns 
 */
export async function getCompanyListTree(params) {
    try {
        return await API.post(COMPANY + 'companyList', params)
    } catch (error) {
        return error;
    }
}
/**
 * 公司列表
 * @param {object} params 
 * @returns 
 */
export async function getCompanyList(params) {
    try {
        return await API.post(COMPANY + 'companyListArr', params)
    } catch (error) {
        return error
    }
}

/**
 * 组织架构树形表
 * @returns 
 */
export async function $osData() {
    try {
        return await API.post(`${COMPANY}organizationalStructure`)
    } catch (error) {
        return error;
    }
}

/**
 * 组织架构-新增公司
 * @param {object} params 
 * @returns 
 */
export async function $osCompanyAdd(params) {
    try {
        return await API.post(`${COMPANY}companyAdd`, params)
    } catch (error) {
        return error;
    }
}

/**
 * 组织架构-删除公司
 * @param {int} id 
 * @returns 
 */
export async function $osCompanyDelete(id) {
    try {
        return await API.post(`${COMPANY}companyDelete`, {
            id
        })
    } catch (error) {
        return error;
    }
}

/**
 * 组织架构-公司详情
 * @param {int} id 
 * @returns 
 */
export async function $osCompanyDetail(id) {
    try {
        return await API.get(`${COMPANY}companyUpdate?id=${id}`)
    } catch (error) {
        return error;
    }
}


/**
 * 组织架构-公司编辑保存
 * @param {object} params 
 * @param {int} id 
 * @returns 
 */
export async function $osCompanyUpdate(params) {
    try {
        return await API.post(`${COMPANY}companyUpdate`, params)
    } catch (error) {
        return error;
    }
}

/**
 * 生成项目二维码
 * @param {int} project_id 
 * @returns 
 */
export async function $osCreateWxQrcode(project_id) {
    try {
        return await API.post(`${COMPANY}createWxQrcode`, {
            project_id
        })
    } catch (error) {
        return error;
    }
}

/**
 * 下载项目二维码
 * @param {int} project_id 
 * @returns 
 */
export async function $osWxQrcodeDown(project_id) {
    try {
        return await API.post(`${COMPANY}wxQrcodeDown`, {
            project_id
        }, {
            responseType: "blob",
        })
    } catch (error) {
        return error;
    }
}