<style lang="scss" scoped>
@import "@assets/styles/variables.scss"; //公共样式变量

.side-bar {
  position: relative;
  background: $menuBg;
  .logo {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: $menuTextColor;
    cursor: pointer;
    img {
      width: 30px;
      height: 30px;
    }
    span {
      margin-left: 10px;
    }
  }
  .side-menu {
    height: calc(100% - 60px);
    overflow-y: auto;
    border: none;
  }
  .side-menu:not(.el-menu--collapse) {
    width: 256px;
  }
}
</style>
<style lang="scss">
@import "@assets/styles/variables.scss"; //公共样式变量

.el-menu-item.is-active {
  background-color: $color-primary !important;
}
</style>

<template>
  <section class="side-bar">
    <h1 class="logo" @click="targetIndex">
      <img :src="logoImg" alt="" />
      <span v-show="!isCollapse">总府物业后台管理</span>
    </h1>
    <el-menu
      class="side-menu"
      unique-opened
      router
      background-color="#191a23"
      text-color="#fff"
      active-text-color="#fff"
      :default-active="active"
      :collapse="isCollapse"
    >
      <el-menu-item index="/homepage" v-if="validateMenuVisible(88)">
        <i class="el-icon-s-home"></i>
        <span slot="title">首页</span>
      </el-menu-item>
      <el-submenu index="2" v-if="validateMenuVisible(1)">
        <template slot="title">
          <i class="el-icon-setting"></i>
          <span slot="title">工单及流程</span>
        </template>
        <el-menu-item-group v-if="validateMenuVisible(2)">
          <el-menu-item index="/workorderprogress/index/list">工单列表</el-menu-item>
        </el-menu-item-group>
      </el-submenu>
      <el-submenu index="3" v-if="validateMenuVisible(14)">
        <template slot="title">
          <i class="el-icon-s-check"></i>
          <span slot="title">组织架构</span>
        </template>
        <el-menu-item-group>
          <el-menu-item v-if="validateMenuVisible(15)" index="/os/index"
            >组织架构</el-menu-item
          >
          <el-menu-item v-if="validateMenuVisible(23)" index="/branch/index/list"
            >部门管理</el-menu-item
          >
          <el-menu-item v-if="validateMenuVisible(27)" index="/frontRole/index/list"
            >员工角色管理</el-menu-item
          >
        </el-menu-item-group>
      </el-submenu>
      <el-submenu index="4" v-if="validateMenuVisible(31)">
        <template slot="title">
          <i class="el-icon-s-custom"></i>
          <span slot="title">账号管理</span>
        </template>
        <el-menu-item-group>
          <el-menu-item v-if="validateMenuVisible(32)" index="/staff/index/list"
            >员工账号</el-menu-item
          >
          <el-menu-item v-if="validateMenuVisible(40)" index="/owner/index/list"
            >业主账号</el-menu-item
          >
        </el-menu-item-group>
      </el-submenu>
      <el-submenu index="5" v-if="validateMenuVisible(46)">
        <template slot="title">
          <i class="el-icon-tickets"></i>
          <span slot="title">数据中心</span>
        </template>
        <el-menu-item-group>
          <el-menu-item
            v-if="validateMenuVisible(47)"
            index="/workOrderApplyBook/index/list"
            >工单台账</el-menu-item
          >
          <el-menu-item
            v-if="validateMenuVisible(50)"
            index="/workOrderApplyReport/index/list"
            >工单报表</el-menu-item
          >
          <el-menu-item v-if="validateMenuVisible(53)" index="/taskApplyBook/index/list"
            >任务台账</el-menu-item
          >
        </el-menu-item-group>
      </el-submenu>
      <el-submenu index="6" v-if="validateMenuVisible(56)">
        <template slot="title">
          <i class="el-icon-edit-outline"></i>
          <span slot="title">资讯及公告</span>
        </template>
        <el-menu-item-group>
          <el-menu-item v-if="validateMenuVisible(57)" index="/article/index/list"
            >资讯管理</el-menu-item
          >
          <el-menu-item v-if="validateMenuVisible(62)" index="/announcement/index/list"
            >公告管理</el-menu-item
          >
          <el-menu-item v-if="validateMenuVisible(67)" index="/about/index/list"
            >关于我们</el-menu-item
          >
        </el-menu-item-group>
      </el-submenu>
      <el-submenu index="7" v-if="validateMenuVisible(69)">
        <template slot="title">
          <i class="el-icon-lock"></i>
          <span slot="title">后台管理中心</span>
        </template>
        <el-menu-item-group>
          <el-menu-item v-if="validateMenuVisible(70)" index="/role/index/list"
            >后台权限资源管理</el-menu-item
          >
          <el-menu-item v-if="validateMenuVisible(74)" index="/roleGroup/index/list"
            >后台角色管理</el-menu-item
          >
          <el-menu-item v-if="validateMenuVisible(82)" index="/member/index/list"
            >后台账号管理</el-menu-item
          >
        </el-menu-item-group>
      </el-submenu>
    </el-menu>
  </section>
</template>

<script>
import { getMemberMenu, memberSmallRoleByPid } from "@api/role";
export default {
  name: "Sidebar",
  props: ["isCollapse"],
  data() {
    return {
      active: "/homepage",
      logoImg: require("@/assets/logo.png"),
      currentMenuList: [],
    };
  },
  watch: {
    async $route(val) {
      console.log("sdf", val);
      this.active = val.meta.parentPath || val.path;
      // memberSmallRoleByPid({ pid: 2 });
      // console.log("sdfds", this.validateMenuVisible(2));
      // console.log("router", val);
    },
  },
  async created() {
    await this.getMemberMenuList();
    this.currentMenuList = this.menuList;
    // setTimeout((_) => {
    //   this.validateSmallRole();
    // }, 500);
    this.active = this.$route.meta.parentPath || this.$route.path;
  },
  methods: {
    validateMenuVisible: function (id) {
      let isVisible = false;
      let findId = function (list, cid) {
        if (list)
          list.forEach((item) => {
            if (item.id == cid) {
              isVisible = true;
            } else findId(item._child, cid);
          });
      };
      findId(this.currentMenuList, id);
      return isVisible;
    },
    targetIndex() {
      if (this.$route.path != "/homepage") {
        this.$router.replace("/homepage");
      }
    },
  },
};
</script>
