import API from '@utils/request';

const ROLE = '/index/';
/**
 * 权限组列表
 * @param {object} params 
 * @returns 
 */
export async function roleGroupList(params) {
    try {
        return await API.get(ROLE + "roleGroupList", {
            params
        });
    } catch (error) {
        return error;
    }
}
/**
 * 权限组回显/修改
 * @param {object} params 
 * @param {string} type 
 * @returns 
 */
export async function roleGroupUpdate(params, type = 'get') {
    try {
        if (type == 'get') {
            return await API.get(ROLE + "roleGroupUpdate", {
                params
            });
        } else {

            return await API.post(ROLE + "roleGroupUpdate", params);
        }
    } catch (error) {
        return error;
    }
}
/**
 * 权限组状态变更
 * @param {object} params 
 * @returns 
 */
export async function roleGroupStatusChange(params) {
    try {
        return await API.post(ROLE + "roleGroupStatusChange", params)
    } catch (error) {
        return error;
    }
}
/**
 * 权限组新增
 * @param {object} params 
 * @returns 
 */
export async function roleGroupAdd(params) {
    try {
        return await API.post(ROLE + "roleGroupAdd", params);
    } catch (error) {
        return error;
    }
}
/**
 * 权限组删除
 * @param {string} id 
 * @returns 
 */
export async function roleGroupDelete(id) {
    try {
        return await API.post(ROLE + "roleGroupDelete", {
            id: id
        });
    } catch (error) {
        return error;
    }
}
/**
 * 获取用户菜单
 * @returns 
 */
export async function getMemberMenu() {
    try {
        return await API.post(ROLE + "memberMenu");
    } catch (error) {
        return error;
    }
}
/**
 * 权限列表
 * @returns
 */
export async function roleList() {
    try {
        return await API.get(ROLE + "roleList");
    } catch (error) {
        return error;
    }
}
/**
 * 权限新增
 * @param {object} params 
 * @returns 
 */
export async function roleAdd(params) {
    try {
        return await API.post(ROLE + "roleAdd", params);
    } catch (error) {
        return error;
    }
}
/**
 * 权限修改
 * @param {object} params 
 * @param {string} type 
 * @returns 
 */
export async function roleUpdate(params, type = "get") {
    try {
        if (type == 'get') {
            return await API.get(ROLE + "roleUpdate", {
                params
            });
        } else {
            return await API.post(ROLE + "roleUpdate", params);
        }
    } catch (error) {
        return error;
    }
}
/**
 * 权限删除
 * @param {string} id 
 * @returns 
 */
export async function roleDelete(id) {
    try {
        return await API.post(ROLE + "roleDelete", {
            id: id
        });
    } catch (error) {
        return error;
    }
}

/**
 * 数据范围
 * @param {string} id 
 * @returns 
 */
export async function roleDataRange() {
    try {
        return await API.post(ROLE + "dataRange");
    } catch (error) {
        return error;
    }
}

/**
 * 获取用户小权限
 * @param {string} id 
 * @returns 
 */
export async function memberSmallRoleByPid(params) {
    try {
        return await API.post(ROLE + "memberSmallRoleByPid", params);
    } catch (error) {
        return error;
    }
}