// 组织架构
import Layout from "@views/layout";

const osRouter = {
    path: "/os",
    name: "OS",
    component: Layout,
    redirect: {
        name: "OS_INDEX"
    },
    children: [{
        path: 'index',
        name: 'OS_INDEX',
        alwaysShow: true,
        meta: {
            title: "组织架构"
        },
        component: () => import("@views/organizationalStructure/index"),
    }]
}

export default osRouter;