/**
 * 工单流程路由
 */
import Layout from "@views/layout";

const workOrderProgressRouter = {
    path: "/workorderprogress",
    name: "WORK_ORDER_PROGRESS",
    component: Layout,
    redirect: {
        name: "WORK_ORDER_PROGRESS_INDEX"
    },
    meta: {
        title: "工单流程",
        sort: 0,
    },
    children: [{
        path: 'index',
        name: 'WORK_ORDER_PROGRESS_INDEX',
        meta: {
            title: '工单流程',
            sort: 2
        },
        component: () => import("@views/workorderprogress/index.vue"),
        redirect: {
            name: "WORK_ORDER_APPLY_REPORT_LIST",
        },
        children: [{
            path: 'list',
            name: 'WORK_ORDER_APPLY_REPORT_LIST',
            meta: {
                title: '工单列表',
                keepAlive: true,
                sort: 1
            },
            component: () => import("@views/workorderprogress/child/list.vue")
        }, {
            path: 'list',
            name: 'WORK_ORDER_APPLY_REPORT_ADD',
            meta: {
                title: '新增工单',
                sort: 2
            },
            component: () => import("@views/workorderprogress/child/add.vue")
        }, {
            path: 'list',
            name: 'WORK_ORDER_APPLY_REPORT_PROGRESS',
            meta: {
                title: '流程配置',
                sort: 3,
                parentPath: '/workorderprogress/index/list'
            },
            component: () => import("@views/workorderprogress/child/progress.vue")
        }, {
            path: 'list',
            name: 'WORK_ORDER_APPLY_REPORT_FORM',
            meta: {
                title: '表单配置',
                sort: 4,
                parentPath: '/workorderprogress/index/list'
            },
            component: () => import("@views/workorderprogress/child/selfform.vue")
        }]
    }]
}


export default workOrderProgressRouter;