import Vue from "vue";

import "normalize.css/normalize.css"; // 替代CSS重置

import "@utils/element-ues"; // 按需引入ElementUI

import "@assets/styles/public.scss"; // 全局引用样式

import App from "./App.vue";
import router from "./router";
import store from "./store";
import Vue2OrgTree from 'vue2-org-tree' // 引入树状结构图
import "vue2-org-tree/dist/style.css" // 引入树状结构图样式

Vue.use(Vue2OrgTree)

import "./permission"; // 权限控制

import _ from "lodash"; // JavaScript 实用工具库

import * as filters from "@/filters"; // 全局过滤器

import * as directives from "./directives"; // 全局过指令

//ueditor
import "../lib/ueditor/ueditor.config.js";
import "../lib/ueditor/ueditor.all.min.js";
import "../lib/ueditor/lang/zh-cn/zh-cn.js";
import "../lib/ueditor/ueditor.parse.min.js";
import {
  memberSmallRoleByPid,
  getMemberMenu
} from "@api/role";

//screenfull
import screenfull from "screenfull";

//echarts
import * as echarts from "echarts";


// lodash挂载
Vue.prototype.$screenfull = screenfull;
Vue.prototype.$echarts = echarts;
Vue.prototype.$_ = _;

/**
 * 流程执行步骤（全局）
 */
Vue.prototype.doList = [{
    id: "do_7",
    name: "执行最后一步",
  },
  {
    id: "do_next",
    name: "执行下一节点",
  },
  {
    id: "do_end",
    name: "结束",
  },
  {
    id: 0,
    name: "重置到之前节点",
  },
]

// 注册全局过滤器
Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key]);
});

// 注册全局过指令
Object.keys(directives).forEach((key) => {
  Vue.use(directives[key]);
});

/**
 * 更新菜单
 */
Vue.prototype.getMemberMenuList = async function () {
  Vue.prototype.menuList = (await getMemberMenu()).data;
  return Vue.prototype.menuList;
}

Vue.prototype.menuList = undefined;

/**
 * 全局小权限
 */
Vue.prototype.smallRoleObj = {};

/**
 * 小权限验证
 */
Vue.prototype.validateSmallRole = async function () {
  await Vue.prototype.getMemberMenuList();
  let currentRouterName = this.$route.name;
  let pid = 0;
  let findRouterName = function (list) {
    if (list) {
      list.forEach(item => {
        if (item.front_route_name == currentRouterName) {
          if (pid == 0)
            pid = item.id;
        } else findRouterName(item._child);
      })
    }
  }
  findRouterName(Vue.prototype.menuList);
  if (pid == 0) {
    Vue.prototype.smallRoleObj = {};
    return;
  }
  let sRoleList = (await memberSmallRoleByPid({
    pid: pid
  })).data;
  let list = sRoleList.map(a => {
    return a.front_small_role_marks
  });
  Vue.prototype.smallRoleObj = {};
  list.forEach(citem => {
    Vue.prototype.smallRoleObj[citem] = true;
  })
  return Vue.prototype.smallRoleObj;
}

Vue.config.productionTip = false;

Vue.prototype.alanayChildFormData = function (childProgressNodeData) {
  let childProgressFormData = {

  };
  try {
    let branchMatch = function () {
      if (childProgressNodeData.branch_id) {
        childProgressFormData.examinetype = 0;
        childProgressFormData.branch_id = childProgressNodeData.branch_id;
      } else if (childProgressNodeData.front_role_id) {
        childProgressFormData.examinetype = 1;
        childProgressFormData.front_role_id = childProgressNodeData.front_role_id;
      } else if (childProgressNodeData.is_assign_user > 0) {
        childProgressFormData.examinetype = 2;
      }

      if (childProgressNodeData.is_branch_leader)
        childProgressFormData.is_branch_leader = childProgressNodeData.is_branch_leader;
    }
    if (childProgressNodeData) {
      if (childProgressNodeData.is_start_user == 1) {
        childProgressFormData.operuser = 0;
      } else {
        // if (childProgressNodeData.branch_id) {
        //   childProgressFormData.examinetype = 0;
        //   childProgressFormData.branch_id = childProgressNodeData.branch_id;
        // } else if (childProgressNodeData.front_role_id) {
        //   childProgressFormData.examinetype = 1;
        //   childProgressFormData.front_role_id = childProgressNodeData.front_role_id;
        // } else if (childProgressNodeData.is_assign_user > 0) {
        //   childProgressFormData.examinetype = 2;
        // }

        // if (childProgressNodeData.is_branch_leader)
        //   childProgressFormData.is_branch_leader = childProgressNodeData.is_branch_leader;
        branchMatch();
      }
      if (childProgressNodeData.is_other == 1 && childProgressNodeData.node == 5) {
        branchMatch();
      }
      childProgressFormData.examinerejectdo =
        childProgressNodeData.no_pass_config.comment == "need";
      childProgressFormData.examinerejectremark = [
        "require",
        "no_require",
        "no_need",
      ].indexOf(childProgressNodeData.no_pass_config.remarks);
      childProgressFormData.examinerejectanno = [
        "require",
        "no_require",
        "no_need",
      ].indexOf(childProgressNodeData.no_pass_config.annex);
      //不通过执行操作
      if (childProgressNodeData.no_pass_config.do && childProgressNodeData.no_pass_config.do.indexOf("retrace_") == 0) {
        //重置到之前的节点
        childProgressFormData.examinerejectdohandler = 0;
        childProgressFormData.examinerejectdoreset = parseInt(childProgressNodeData.no_pass_config.do.split(
          "_"
        )[1]);
      } else
        childProgressFormData.examinerejectdohandler =
        childProgressNodeData.no_pass_config.do;
      //审核通过
      childProgressFormData.examineresoveremark = [
        "require",
        "no_require",
        "no_need",
      ].indexOf(childProgressNodeData.pass_config.remarks);
      childProgressFormData.examineresoveanno = [
        "require",
        "no_require",
        "no_need",
      ].indexOf(childProgressNodeData.pass_config.annex);
      if (childProgressNodeData.pass_config.comment == 'need')
        childProgressFormData.examineresovedo = true;
      childProgressFormData.examineresoveisover =
        childProgressNodeData.pass_config.do == "do_7" ? 1 : 0;
      if (
        childProgressNodeData.other_msg_push &&
        childProgressNodeData.other_msg_push.indexOf("last_user") >= 0
      )
        childProgressFormData.prevstep = true;
      if (
        childProgressNodeData.other_msg_push &&
        childProgressNodeData.other_msg_push.indexOf("issue_user") >= 0
      )
        childProgressFormData.creatuser = true;
    }
  } catch (err) {
    console.log('dsfdsfdsfdsf', err)
  }

  return childProgressFormData;

}

const myVue = new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");