/**
 *前台员工
 */
import Layout from "@views/layout";

const staffRouter = {
    path: '/staff',
    name: 'STAFF',
    component: Layout,
    redirect: {
        name: 'STAFF_INDEX'
    },
    meta: {
        title: '员工账号',
        sort: 0
    },
    children: [
        {
            path: 'index',
            name: 'STAFF_INDEX',
            component: () => import("@views/staff/index.vue"),
            meta: {
                title: '员工账号',
                sort: 1
            },
            redirect: {
                name: 'STAFF_LIST'
            },
            children: [
                {
                    path: 'list',
                    name: 'STAFF_LIST',
                    meta: {
                        title: '员工账号',
                        sort: 2
                    },
                    component: () => import("@views/staff/child/list.vue")
                },
                {
                    path: 'add',
                    name: 'STAFF_ADD',
                    meta: {
                        title: '新增',
                        parentPath: '/staff/index/list',
                        operate: true,
                    },
                    component: () => import("@views/staff/child/form.vue")
                },
                {
                    path: 'edit/:id',
                    name: 'STAFF_EDIT',
                    meta: {
                        title: '编辑',
                        parentPath: '/staff/index/list',
                        operate: true,
                    },
                    component: () => import("@views/staff/child/form.vue")
                }
            ]
        }
    ]
}

export default staffRouter;