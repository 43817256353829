/**
 * 前台角色
 */
 import Layout from "@views/layout";

 const frontRoleRouter = {
     path: "/frontRole",
     name: "FRONT_ROLE",
     component: Layout,
     redirect: { name: "FRONT_ROLE_INDEX" },
     meta: {
         title: "员工角色管理",
         sort: 0,
     },
     children: [{
         path: 'index',
         name: 'FRONT_ROLE_INDEX',
         meta: {
             title: '员工角色管理',
             sort: 2
         },
         component: () => import("@views/frontRole/index.vue"),
         redirect: {
             name: "FRONT_ROLE_LIST",
         },
         children: [
             {
                 path: 'list',
                 name: 'FRONT_ROLE_LIST',
                 meta: {
                     title: '员工角色管理',
                     sort: 2
                 },
                 component: () => import("@views/frontRole/child/list.vue")
             },
             {
                 path: 'add',
                 name: 'FRONT_ROLE_ADD',
                 meta: {
                     title: '新增',
                     operate: true,
                     parentPath: "/frontRole/index/list",
                 },
                 component: () => import("@views/frontRole/child/form.vue")
             },
             {
                 path: "edit/:id",
                 name: 'FRONT_ROLE_EDIT',
                 meta: {
                     title: '修改',
                     operate: true,
                     parentPath: "/frontRole/index/list"
                 },
                 component: () => import("@views/frontRole/child/form.vue")
             }
         ]
     }]
 }
 
 
 export default frontRoleRouter;