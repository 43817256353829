/**
 * 前台角色
 */
 import Layout from "@views/layout";

 const taskApplyBookRouter = {
     path: "/taskApplyBook",
     name: "TASK_APPLY_BOOK",
     component: Layout,
     redirect: { name: "TASK_APPLY_BOOK_INDEX" },
     meta: {
         title: "任务台账",
         sort: 0,
     },
     children: [{
         path: 'index',
         name: 'TASK_APPLY_BOOK_INDEX',
         meta: {
             title: '任务台账',
             sort: 2
         },
         component: () => import("@views/taskApplyBook/index.vue"),
         redirect: {
             name: "TASK_APPLY_BOOK_LIST",
         },
         children: [
             {
                 path: 'list',
                 name: 'TASK_APPLY_BOOK_LIST',
                 meta: {
                     title: '任务台账',
                     sort: 2
                 },
                 component: () => import("@views/taskApplyBook/child/list.vue")
             },
             {
                 path: "detail/:id",
                 name: 'TASK_APPLY_BOOK_DETAIL',
                 meta: {
                     title: '详情',
                     operate: true,
                     parentPath: "/taskApplyBook/index/list"
                 },
                 component: () => import("@views/taskApplyBook/child/detail.vue")
             }
         ]
     }]
 }
 
 
 export default taskApplyBookRouter;