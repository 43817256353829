import API from "@utils/request";

const BRANCH = "/index/";

/**
 * 部门树状数据列表
 * @param {object} params 
 * @returns 
 */
export async function getBranchList(params) {
    try {
        return await API.post(BRANCH + "branchList", params);
    } catch (error) {
        return error;
    }
}

/**
 * 同类型部门列表-二维数组
 * @param {object} params 
 * @returns 
 */
export async function getBranchListByType(params) {
    try {
        return await API.post(BRANCH + "branchListByType", params);
    } catch (error) {
        return error;
    }
}
/**
 * 编辑/回显
 * @param {object} params 
 * @param {string} type 
 * @returns 
 */
export async function branchUpdate(params, type = 'get') {
    try {
        if (type == 'get') {
            return await API.get(BRANCH + "branchUpdate", { params });
        } else {
            return await API.post(BRANCH + "branchUpdate", params);
        }

    } catch (error) {
        return error;
    }
}
/**
 * 新增
 * @param {object} params 
 * @returns 
 */
export async function branchAdd(params) {
    try {
        return await API.post(BRANCH + "branchAdd", params);
    } catch (error) {
        return error;
    }
}
/**
 * 删除
 * @param {string} id 
 * @returns 
 */
export async function branchDelete(id) {
    try {
        return await API.post(BRANCH + "branchDelete", {
            id: id,
        });
    } catch (error) {
        return error;
    }
}