/**
 *公告
 */
 import Layout from "@views/layout";

 const announcementRouter = {
     path: '/announcement',
     name: 'ANNOUNCEMENT',
     component: Layout,
     redirect: {
         name: 'ANNOUNCEMENT_INDEX'
     },
     meta: {
         title: '公告管理',
         sort: 0
     },
     children: [
         {
             path: 'index',
             name: 'ANNOUNCEMENT_INDEX',
             component: () => import("@views/announcement/index.vue"),
             meta: {
                 title: '公告管理',
                 sort: 1
             },
             redirect: {
                 name: 'ANNOUNCEMENT_LIST'
             },
             children: [
                 {
                     path: 'list',
                     name: 'ANNOUNCEMENT_LIST',
                     meta: {
                         title: '公告管理',
                         sort: 2
                     },
                     component: () => import("@views/announcement/child/list.vue")
                 },
                 {
                     path: 'add',
                     name: 'ANNOUNCEMENT_ADD',
                     meta: {
                         title: '新增',
                         sort: 3,
                         parentPath: '/announcement/index/list',
                     },
                     component: () => import("@views/announcement/child/form.vue")
                 },
                 {
                     path: 'edit/:id',
                     name: 'ANNOUNCEMENT_EDIT',
                     meta: {
                         title: '编辑',
                         sort: 4,
                         parentPath: '/announcement/index/list'
                     },
                     component: () => import("@views/announcement/child/form.vue")
                 }
             ]
         }
     ]
 }
 
 export default announcementRouter;