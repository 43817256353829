import API from "@utils/request";

const MEMBER = "/index/";

/**
 * 会员登录
 * @param {object} params
 */
export async function login(params) {
  try {
    return await API.post(MEMBER + "login", params);
  } catch (error) {
    return error;
  }
}
/**
 * 登录人信息
 * @returns 
 */
export async function getMyInfo() {
  try {
    return await API.post(MEMBER + "getMyInfo");
  } catch (error) {
    return error;
  }
}
/**
 * 修改密码
 * @returns 
 */
export async function memberPasswordUpdate3(params) {
  try {
    return await API.post(MEMBER + "memberPasswordUpdate", params);
  } catch (error) {
    return error;
  }
}
/**
 * 获取用户列表（分页）
 * @param {object} params
 */
export async function getMemberList(params) {
  try {
    return await API.get(MEMBER + "memberList", {
      params,
      headers: { isLoading: true },
    });
  } catch (error) {
    return error;
  }
}
/**
 * 获取用户列表（不分页）
 * @param {object} params 
 * @returns 
 */
export async function getMemberListAll(params) {
  try {
    return await API.get(MEMBER + "memberListAll", { params })
  } catch (error) {
    return error;
  }
}

/**
 * 获取用户详情
 * @param {number} id
 */
export async function getMemberInfo(id) {
  try {
    return await API.get(MEMBER + "getmemberfobyid", {
      params: {
        id,
      },
    });
  } catch (error) {
    return error;
  }
}
/**
 * 编辑-回显
 * @param {object} params 
 * @returns 
 */
export async function memberUpdate(params, method = 'get') {
  try {
    if (method == 'get') {
      return await API.get(MEMBER + "memberUpdate", {
        params: params
      });
    } else {
      return await API.post(MEMBER + "memberUpdate", params);
    }

  } catch (error) {
    return error;
  }
}
/**
 * 新增
 * @param {object} params 
 * @returns 
 */
export async function memberAdd(params) {
  try {
    return await API.post(MEMBER + "memberAdd", params);
  } catch (error) {
    return error
  }
}
/**
 * 删除
 * @param {object} params 
 * @returns 
 */
export async function memberDelete(id) {
  try {
    return await API.post(MEMBER + "memberDelete", {
      id:id
    });
  } catch (error) {
    return error;
  }
}
export async function loginStatusUpdate(id) {
  try {
    return await API.post(MEMBER + "loginStatusUpdate", {
      id:id
    });
  } catch (error) {
    return error;
  }
}
