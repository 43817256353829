import API from "@utils/request";

const AUTHORITY = "/index/";
/**
 * 树状列表
 * @returns 
 */
export async function roleList() {
    try {
        return await API.get(AUTHORITY + "roleList");
    } catch (error) {
        return await error;
    }
}
/**
 * 回显/修改
 * @param {object} params 
 * @param {string} type 
 * @returns 
 */
export async function roleUpdate(params, type = 'get') {
    try {
        if (type == 'get') {
            return await API.get(AUTHORITY + "roleUpdate", { params });
        } else {
            return await API.post(AUTHORITY + "roleUpdate", params);
        }

    } catch (error) {
        return error;
    }
}
/**
 * 新增
 * @param {object} params 
 * @returns 
 */
export async function roleAdd(params) {
    try {
        return await API.post(AUTHORITY + "roleAdd", params);
    } catch (error) {
        return error;
    }
}
/**
 * 删除
 * @param {string} id 
 * @returns 
 */
export async function roleDelete(id) {
    try {
        return await API.post(AUTHORITY + "roleDelete", {
            id: id
        });
    } catch (error) {
        return error;
    }
}